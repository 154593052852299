// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  default: {
    search: 'Search',
    showResults: 'Show results',
    showDetail: 'Show detail',
    showFilter: 'Show filter',
    hideFilter: 'Hide filter',
    cultureObjects: 'Object catalogue',
    countSheets: 'Census forms',
    multimedia: 'Video gallery',
    articles: 'Articles',
    articles_blog: 'Articles',
    articles_newsfeed: 'Articles',
    map: 'Map',
    aboutSlovakiana: 'About Slovakiana',
    funny: 'Edu materials',
    notFound: 'Not found',
    noResultsFound: 'No results found',
    moreResults: 'Search for more results. Count: ',
    home: 'Home',
    Subcategory: 'Subcategory',
    favorites: 'Favourites',
    moreInfo: 'More info',
    loadMore: 'Show more',
    order: 'Sort',
    chooseCat: 'Favorite item type',
    bactToList: 'Back to the list',
    next: 'Next',
    prev: 'Previous',
    mentionId: 'This object is mentioned in article',
    license: 'License',
    cultureObjectDetail: 'Cultural object',
    multimediaDetail: 'Multimedia object',
    countingSheetsDetail: 'Census form',
    articlesDetail: 'Article',
    articlesDetail_blog: 'Article',
    articlesDetail_news: 'Article',
    confirm: 'Confirm',
    decline: 'Decline',
    cancel: 'Cancel the action',
    share: 'Share',
    print: 'Print',
    download: 'Download',
    favorite: 'Add to favourites',
    institutionDetial: 'Institutions',
    companies: 'List of institutions',
    foundForYou: 'We found ',
    clearAll: 'Clear all filters',
    copyLink: 'Copy link',
    copyIframe: 'Copy',
    copyIframeSuccess: 'Iframe was successfully copied',
    showMore: 'Show more',
    showLess: 'Show less',
    noCategory: 'No category',
    goUp: 'Back to top',
    noResults: 'Sorry, there are no results',
    noText: 'Sorry, this field is empty.',
    page: 'Page',
    of: 'of',
    authorRights: 'Author rights',
    pageNumber: 'Page',
    outOfBounds: 'Out of bounds!',
    samsungAlert1: 'Your browser (Samsung Internet) may not show this website correctly. ',
    samsungAlert2: 'Please consider using a standards-compliant browser instead.',
    samsungAlert3: 'We recommend Firefox, Microsoft Edge, or Google Chrome.',
    vrFirefoxALert:
      'The Firefox browser currently does not support VR video playback on the Slovakiana portal. To view the virtual tour, please use a different web browser.',
  },
  carousel: {
    art: 'Fine art and design',
    text: 'Text documents',
    history: 'Historical collection',
    arch: 'Architecture and monuments',
    video: 'Audio-visual content',
    cult: 'Traditional folk culture',
    nature: 'Natural heritage',
  },
  footer: {
    stayInContact: 'Let’s keep in touch',
    aboutSlovakiana: 'About Slovakiana',
    aboutSlovakianaText:
      'Slovakiana creates an environment in which everyone can get to know their culture and history in a simple way.We preserve the cultural heritage for future generations.',
    contact: 'Contact',
    help: 'Help',
    companies: 'List of administrative institutions',
    accesibility: 'Accessibility statement',
    conditions: 'General conditions',
    authorRights: 'Copyright',
    newsletter1: 'Sign up for our newsletter',
    nwesletter2: '',
    Popupnewsletter1: 'Keep in touch!',
    Popupnewsletter2: 'Subscribe to our monthly newsletter and receive news and stories directly in your inbox.',
    writeEmail: 'Enter your email',
    login: 'Sign up',
    confirm1: 'By submitting you agree to the processing ',
    confirm2: 'personal data.',
  },
  homepage: {
    title: `Explore more than`,
    titlePart2: ` digitized cultural heritage objects in one place.`,
    subtitle: 'Having trouble exploring cultural heritage?',
    rerollButt: 'Start with a random collection',
    searchPlaceholder: 'Enter a title, author’s name, location, or keyword',
    searchPlaceholderMobile: `Let's discover together`,
    chooseCat: 'Video genre',
    news: ' News',
    articles: 'Discover stories about Slovak cultural heritage',
    showMoreArticles: 'Show all articles',
    aboutSl: 'About Slovakiana',
    aboutSlSubtitle:
      'Platform which provides access to the digitized cultural objects, monuments,<br>and traditional folk culture. It preserves the Slovak cultural heritage<br>for future generations.',
    cultureObjectsText:
      'Enjoy exploring<br>the collection of digitized objects<br>from museums, galleries, libraries,<br>and other stakeholders.',
    countSheetsText:
      'Discover your family and dwelling history<br>in digitized census forms<br>from the 1930, 1939, and 1940 census.',
    multimediaText:
      'Get to know your cultural heritage<br>through both video and sound!<br>Watch our reports, instructional videos,<br>and educational animations.',
    articlesText:
      'Read the stories<br>to uncover the compelling histories<br>of monuments, arts, traditions<br>and people that came before us.',
    mapText: 'Discover places that have a lot to offer<br>and visit monuments and institutions<br>not just on the map.',
    funnyText:
      'Educational materials for young and old<br>who want to have fun and learn something new<br>at the same time.',
    explore: 'Explore the monument',
    mapTitle: 'We recommend visiting',
    exploreOnMap: 'Show the map of monuments and institutions',
    mostReaded: 'Most popular articles on Slovakiana',
  },
  sheet: {
    title: 'Get to know your ancestors',
    subtitle: `through the digitized census forms from 1930, 1939, and 1940 census.`,
    searchText: 'Enter a district, municipality or street',
    noBlacked: 'Only public',
    countSheets: ' results',
    district: 'District',
    searchDistrict: 'Search district',
    town: 'Municipality',
    searchTown: 'Search municipality',
    street: 'Street',
    year: 'Year of census',
    searchYear: 'Search year of cenus',
    tooltip: `The 1939 and 1940 census forms are only available in a censored version as the 90-year period of personal data protection has not passed yet.`,
    chooseFirst: 'Select the district first',
  },
  objects: {
    loading: 'Loading...',
    downloading: 'Downloading...',
    title: 'The catalogue of the Slovak cultural heritage',
    subtitle: 'allows access to digitized cultural objects from museums, galleries, libraries, and other stakeholders.',
    info: 'Thematic collections of objects prepared by the Slovakiany team',
    searchText: 'Enter a title, author’s name, location, or keywords',
    withPicture: 'With picture only',
    forDownload: 'For download only',
    countObjects: ' results',
    chooseCat: 'Category',
    searchCat: 'Search category',
    authorTag: 'Author',
    materialTag: 'Material',
    techniqueTag: 'Technique',
    periodTag: 'Dating',
    institutionTag: 'Administrative institution',
    formTag: 'Form',
    locationTag: 'Location',
    searchAuthorTag: 'Search author',
    searchMaterialTag: 'Search material',
    searchTechniqueTag: 'Search technique',
    searchPeriodTag: 'Search period',
    searchInstitutionTag: 'Search institution',
    searchFormTag: 'Search form',
    searchLocationTag: 'Search location',
    similaryObjects: 'Similar objects',
    distance: 'Radius (distance): ',
    filterMap: 'Search location',
    clearMap: 'Remove location',
    author: 'Search author',
    date: 'Search dating',
    material: 'Search material',
    technic: 'Search technique',
    institution: 'Search administrative institution',
    medium: 'Search medium',
    category: 'Search categories',
    subcategory: 'Subcategory',
    dateFrom: 'From date',
    dateTo: 'To date',
    showMore: 'Show all',
    suggested: 'Recommended collections',
    show: 'Show',
    hide: 'Hide',
    giveCredit: 'Give credit to www.slovakiana.sk',
    doNotCommerical: 'Do not use the object for commercial purposes',
    doNotDerivate: 'Do not modify or expand',
    license: 'license',
    licenseBY:
      'You can expand, modify and use the object for commercial purposes as well, with the obligation to indicate the source www.slovakiana.sk.',
    licenceCC: 'Sorry, we cannot make this digital object available due to copyright protection.',
    licenseBYusage: ' authorizes you to use the object under the following conditions: ',
    licenseBYND:
      'You can expand, modify and use the object only for non-commercial purposes with the obligation to indicate the source www.slovakiana.sk.',
    licenseBYSA: 'You cannot expand, modify and use the object even for non-commercial purposes.',
    licenseMore: 'More about the license ',
  },
  multimedia: {
    title: 'Get to know your cultural heritage through both video and sound ',
    subtitle: 'watch our reports, instructional videos, and educational animations.',
    searchText: 'Enter a keyword',
    forDownload: 'For download only',
    countMultimedia: ' results',
  },
  companies: {
    title: `List of museums, galleries, monuments, and other stakeholders,`,
    subtitle: 'whose cultural objects you can find on Slovakiana.',
    searchText: 'Enter name of the institution',
    countComp: ' results',
    area: 'Locality',
    type: 'Type of institution',
    chooseCat: 'Institution category',
    searchCat: 'Search category',
    chooseLoc: 'Location',
    searchLoc: 'Search location',
  },
  favorites: {
    title: `Your favourite objects and articles in one place.`,
    subtitle: `Create your own
collection for an easy access to your favourites when you are back on
Slovakiana`,
    info: `Create temporary file with no need for registration, and keep your favourites saved in this computer until you clear your browser's cache.`,
    searchText: 'Enter your favourite',
    countFavStart: 'In the list you have',
    countFav: ' favorites items',
    runPresent: 'Start slideshow',
    edit: 'Edit items',
    endEdit: 'End of editing',
    clearAll: 'Delete all',
    emptyList: 'There are no items in the favorites list.',
    clearText: 'Are you sure that you want to delete your favorite',
    categoryTypes: 'Types of favorite items',
    category1: 'Census forms',
    category2: 'Articles and monuments',
    category3: 'Cultural objects',
    category4: 'Videogallery',
    category6: 'EDU materials',
    category5: 'Institutions',
  },
  map: {
    // title: 'Discover places which have a lot to offer',
    title: 'Enter a location, monument, or institution',
    subtitle: 'Visit monuments, museums, galleries and other cultural institutions in Slovakia.',
    searchText: 'Enter a location, monument, or institution',
    countTrip: ' results',
    typeFilter: 'Tips of monuments',
    withText: 'With description',
    institution: 'Institution',
    monument: 'Monument',
    group: 'Group of monuments and institutions',
  },
  articles: {
    title: 'An opportunity to learn more ',
    subtitle: 'about monuments, arts, or traditions can be found in the popular-educational articles.',
    searchText: 'Enter a keyword',
    countArticle: ' results',
    countArticle1: ' result',
    countArticle234: ' results',
    withText: 'With description',
    nextQuestion: 'Next question',
    prevQuestion: 'Previous question',
    resultQuiz: 'Complete the quiz',
    restart: 'Restart',
    noAnswer: 'You have to check at least one option.',
    from: 'with',
    result: 'score',
    result2: 'Amazing, your knowledge deserves an A+!',
    result3: 'Wow, you rock at this!',
    result4: `It's okay, you will do better next time.`,
    result5: 'No worries! Re-read the article and try again :)',
    similarObjects: 'Related objects',
    similarObjectsInst: 'Objects of the administrative institution',
    name: 'Name',
    address: 'Address',
    position: 'Coordinates',
    clickToCopy: '* Click to copy the information to the clipboard *',
    selectAuthor: 'Author of the article',
    chooseCat: 'Thematic area',
    searchCat: 'Search thematic area',
    chooseCatMap: 'Institution and monument category',
    searchCatMap: 'Search category',
    category: 'Category',
    article: 'Article',
    monument: 'Monument',
    institution: 'Institution',
    no_type: 'No type',
    related: 'Related articles',
    quizTitle: 'Quiz yourself!',
    videoTitle: 'Video Link: ',
    author: 'Author',
    date: 'Date',
  },
  about: {
    title: `The story of the Slovakian portal`,
    upperText1: `Slovakiana is an environment that provides enthusiasts, professionals, teachers, students and researchers
    access to Slovak cultural heritage and sheets from the population census of Slovakia.
    It makes digitized objects available in high resolution, which is possible in addition to viewing i
    share, download and further use in various industries, if their copyright allows
    protection.`,
    upperText2: `The web portal was launched in 2015 and currently contains almost 800,000 digitized
    museum objects, textual and archival documents, monuments, objects of fine art,
    of traditional folk culture, natural heritage and film production, which come from more than 150
    Slovak administrative institutions.`,
    upperText3: `The digitalization of cultural objects is provided by the Slovak National Gallery, the Slovak National Gallery
    library, Slovak Film Institute, Memorial Office of the Slovak Republic, Malokarpatské awareness center, Státna
    the scientific library in Prešov and the Slovak Folk and Art Collective and the National Awareness Center, which
    is the operator of the Slovakiana portal.`,
    lowerTextTitle: `What is the goal of Slovakiana?`,
    lowerText: `The main goal of the portal is to make the results of the digitization of the cultural heritage of Slovakia, which are provided by various memory and fund institutions.You will find collection items from more than 70 Slovak institutions (museums, galleries, libraries, educational centers and other institutions) whose digitization is provided: `,
    lowerTextList1: `Slovak National Gallery`,
    lowerTextList2: `Slovak National Library`,
    lowerTextList3: `Slovak Film Institute`,
    lowerTextList4: `Monuments Board of the Slovak Republic`,
    lowerTextList5: `National Educational Center`,
    lowerTextList6: `Little Carpathian Cultural and Educational Centre in Modra`,
    lowerTextList7: `State Scientific Library in Prešov`,
    lowerTextList8: `Slovak Folk and Art Collective`,
    sendMessage: `Send a message`,
    msgQuestion: `In case of questions, do not hesitate to write us.`,
    questionTitle: `FAQ`,
    questionTitle2: `SEARCH ON SLOVAKIANA PORTAL`,
    question1: `What is the cultural heritage of Slovakia?`,
    answer1: `<div>Cultural heritage is a set of tangible and intangible cultural objects, creative human activity and
    the development of human society from the earliest times to the present day, bearing in mind their historical and
    cultural and social value.</div>
    <div><b>Intangible heritage</b> is represented by traditions, folklore, songs, poems, rumours, musical works, rituals,
    social customs, knowledge and experience related to nature and the universe or knowledge a
    skills needed for traditional crafts.</div>
    <div><b>Material heritage</b> is represented by creations of human activity and objects of the past. This includes monuments
    and monument areas, museum and gallery objects, archival documents, including film and
    audio documents, archives, documents and other text documents.</div>
    `,
    answer12: `here.`,
    question2: `What is a cultural and digital object?`,
    answer2: `By the term cultural object, we mean a collection item located in a museum, gallery, library, etc
    another administrative institution (e.g. painting, museum object, book, film, but also national cultural
    monument). Visitors to the Slovakiana portal have the opportunity to view digital representations of these
    cultural objects, i.e. digital objects in various formats (photo, panorama, video, audio,
    3D).`,
    question3: `What is a trustee institution?`,
    answer3: `Administrative institution is a memory and fund institution that manages and preserves cultural objects in
    with its collection fund (museums, galleries, libraries and others), manages and protects the monument fund or
    documents intangible cultural heritage and traditional folk culture.
    Administrative institutions decide which cultural objects are digitized and subsequently published on
    Slovakian portals. You can learn more about individual institutions in `,
    answer31: 'List of administrative institutions.',
    question4: `Why can't I see all digitized cultural objects (digital
      objects)?`,
    answer4: `<div>How the legal protection of cultural objects is set is determined by the administrative institution that preserves them
    objects in their collection fund (museums, galleries, libraries, etc.). The Slovakian portal does not specify which
    cultural objects are made available to the public.</div>
    <div>The following can be made available on the Slovakian portal:</div>
    <ul>
    <li>cultural objects that are not protected by copyright;</li>
    <li>cultural objects for which there is no other legal obstacle to their access (e.g. display
      personal data);</li>
    <li>cultural objects that can be made accessible on the basis of a legal exception/specific regime;</li>
    <li>cultural objects where it is possible to make them available on the basis of a concluded license agreement
    with the owner/administrator of the cultural object;</li>
    <li>cultural objects for which the term of protection has already expired.</li>
    </ul>
    <div>A large number of cultural objects from paintings,
    historical objects, books up to digital display of large immovable objects such as castles
    and various monuments. The possibility of making cultural objects accessible depends on the assessment of the legal status
    protection that applies to the cultural object. Even with regard to the typology of cultural objects, it goes
    most often for copyright protection. The influence and connection with protection cannot be neglected either
    personal data, classified facts, or the very intention of protection, for example
    archaeological site in the form of not displaying some metadata data. Therefore, even if
    it is not a cultural object protected by copyright, there may be a situation that such an object cannot be
    made available at all or only to a certain extent.</div>
    <div>The primary legal regulation in the field of copyright is Act No. 185/2015 Coll. copyright law,
    which regulates the prerequisites for the creation of author's works, the possibilities of their permitted use, and the length
    protection periods, but also many other related topics.
    Every author's work is subject to copyright. Copyright includes exclusive personal rights
    rights (the right to mark/not to mark your work with a name or pseudonym, to decide on the publication of your
    work, the right to the inviolability of the work) and exclusive property rights (use the work and grant consent to
    each use of the work). Personal rights are non-transferable and property rights expire upon the death of the author
    they last during the life of the author and 70 years after his death. During this period of ownership rights, the work is
    protected, the work can only be used with the consent of the author or other right holder. Copyright Act no.
    618/2003 of from. on copyright and copyright-related rights (Copyright Act) in § 56
    demonstratively states what the author can demand in case of unauthorized intervention or threat
    of such an interference with his right.</div>
    `,
    question5: `Why are some tally sheets blacked out?`,
    answer5: `<div>For legislative reasons, it is not possible to make available on the Slovakiana portal objects that
    fall under copyright protection or contain personal or sensitive data of residents
    Slovak Republic. In the case of blacking out, it is related to the protection of personal data, where
    due to legal obligations, we were forced to temporarily black out some parts. This concerns
    sheets from the population census in 1939 and 1940. After 90 years since the census
    population, it will be possible to make these sheets available to the public.</div>
    <div>For that reason, the National Awareness Center is not the operator of the portal
    Slovakian, authorized to provide access to unblackened enumeration sheets, or otherwise about them
    provide information.</div>
    <div>The blackened enumeration sheets on the Slovakiana portal serve the public primarily as an aid in
    research, thanks to which quick identification of the census sheet in Slovakia is possible
    national archive, on the basis of a unique identification number found in
    in the name of the census sheet made available on the Slovakana portal.</div>
    <div>If you are interested in a non-blackened paper or digital copy of the census sheet,
    it is necessary to submit an application to the correspondence address of the Slovak National Archives. FROM
    due to the protection of census sheets from 1939 and 1940, these are not submitted for study, but
    only their copies are made for payment of costs.</div><br>
    Application form<br>
    Email contact: <a href="mailto:archiv.sna@minv.sk">archiv.sna@minv.sk</a><br>
    Address for submission of applications: Ministerstvo vnútra Slovenskej Republiky, Slovenský národný
    archív, Drotárska cesta 42, P.O.Box 115, 840 05 Bratislava 45`,
    question6: `Why can't I find some of the cultural objects I know from the museum/gallery?`,
    answer6: `Digitization of cultural heritage is still an ongoing process, and therefore not all cultural objects have been
    digitized. At the same time, the selection of objects that are made available on the Slovakian portal is within the competence
    administrative institutions themselves, as well as determining their copyright protection`,
    question7: `Can I also contribute to Slovakian?`,
    answer7: `On the Slovakana portal, we only make available cultural objects originating from administrative ones
    institutions. However, if you would like to share knowledge about any of the cultural objects, national
    cultural monument or locality, you can send us your contributions (max. 1.5 standard pages) to
    <a href="mailto:slovakiana@nocka.sk">contact email</a>and after assessment we will publish it on the portal in the Articles section.`,
    question8: `You have found an antique at home, or an object similar to the one you see on
    Slovakian? Do you want to know more about him and what I dream about? Who to turn to?`,
    answer8: `You often contact us with a request to provide more comprehensive information about antiques,
    which you found or bought and you see similar items on the portal. However, the Slovakian team
    does not work with specific cultural objects, but ensures their online presentation from
    results of digitalization of administrative institutions.
    If you have a question regarding cultural objects, you can contact the administrator directly
    institution (listed in the description of the object). You can find the contact information of individual institutions
    in `,
  },
  edu: {
    title: `Educational materials for both children and adults,`,
    subtitle: 'who want to    learn something new while having fun.',
    searchText: 'Enter a keyword',
    countObjects2: ' results',
    download: 'Download',
    preview: 'Preview',
    chooseCat: 'Type of Educational Material',
  },
  preview: {
    noPreview: 'This digital object has no preview.',
    license:
      'The cultural object is digitized. Due to legal protection, it is not possible to display it through the Slovakian portal. To obtain more detailed information about the cultural object, you can contact the institution that professionally manages the object.',
    nodigitalized: 'Cultural object is not digitalized.',
  },
  sort: {
    sheetName: 'Box number',
    name: 'Title',
    date: 'Date',
    relevance: 'Relevance',
    popularity: 'Popularity',
    update: 'Update',
    municipality: 'Municipality',
  },
  acessibility: {
    title: 'CONTENT ACCESSIBILITY STATEMENT',
    text1: `The legal protection of cultural objects is determined by the cultural heritage institution or a
    stakeholder that keeps the objects in its collections (museums, galleries, libraries, etc.). Slovakiana
    does not determine which cultural objects are made available to the public`,
    text5: `Unlike economic rights, moral rights cannot be sold or otherwise transferred, and they expire upon the
    death of the author. Property rights last during the life of the author and 70 years after their death.
    During this period, the work is protected, and it is possible to use the work only with the consent of
    the author or the right holder.</br></br>
    Section 50 of the Copyright Act no. 618/2003 demonstratively states what can be demanded by the
    author (owner) in case of unauthorized interference or a danger of such interference with their right.
    `,
    text3: `Slovakiana allows you to explore a large number of cultural objects - from paintings to large immovable
    objects, such as castles and monuments. The possibility of making cultural objects accessible depends
    on the assessment of the state of legal protection that applies to the cultural object. Considering the
    typology of cultural objects, copyright protection is most often the case. We cannot forget the influence
    of and connection to the protection of personal data, classified facts, or the very intention of
    protecting, for example, an archaeological site in the form of not displaying some metadata. Therefore,
    even if it is not a cultural object protected by copyright, a situation may arise that such an object
    cannot be made available at all or only to a certain extent.`,
    text4: `The primary legal framework and foundation in the field of copyright is the Act No. 185/2015: The
    Copyright law. This act regulates the prerequisites for the creation of copyright in one´s work, the
    possibilities for its use, the length of the protection period, but also many other related topics. </br></br>
    Every author's work is subject to copyright. If someone owns the copyright of a work, they have the
    exclusive right over certain uses of that work. These rights fall into two categories: economic and
    moral rights. Economic (property) rights give one the opportunity to make commercial gain from the
    exploitation of one´s works. This would usually be by licensing others to use the work, or by selling the
    rights. Exclusive moral rights can be very special to the person who creates them. They generate from
    personal exploitation, and can assess whether to mark or not one´s work with a name or pseudonym,
    or deciding on the publication of one´s work. `,
    text2: `The following can be made available on Slovakiana:
    <br/>
    <ul>
      <li>cultural objects that are not protected by copyright;</li>
      <li>cultural objects for which there is no other legal obstacle to making them available (e.g. display of
        personal/sensitive data);</li>
      <li>cultural objects that can be made accessible on the basis of a legal exception/specific regime;</li>
      <li>kultúrne objekty, pri ktorých je možnosť ich sprístupnenia na základe uzatvorenej licenčnej zmluvy
      s vlastníkom/ správcom kultúrneho objektu;</li>
      <li>kultúrne objekty, pri ktorých už uplynula lehota ochrany.</li>
    </ul>`,
  },
  rights: {
    title: 'CENTRE FOR COPYRIGHT',
    text1:
      '<b> Copyright Center </b> (CPAP) represents a professional background for dealing with the legal -technical pitfalls of the digitization process with the aim of identifying the legal protection of individual cultural objects with the possibility of their further use, primarily access through the created central portal of the cultural heritage of Slovakia - Slovakiana - Slovakiana - . CPAP is an institution that was created as a project output of the Central Application Infrastructure and Registrar </b>, and in a significant way it helps to achieve copyright and rights protection related to copyright in digitization and disclosure of digitized public content. In his work activities he ensures several professional tasks both methodological and practical character, aimed at addressing specific licensing relations.',
    text2: `In particular, these activities are:
    <ul>
      <li>Creating and updating the methodology for categorization of cultural objects from the perspective of copyright,</li>
      <li> Creation and updating methodology for categorization of metadata to cultural objects, </li>
       <li> Negotiations with authors to obtain the author's consent to make the work available, </li>
       <li> consulting activity, </li>
       <li> Cooperative activity, </li>
       <li> Administrative and professional support, </li>
       <li> Legal advice on memory and fund institutions (PFI) in the field of copyright, </li>
       <li> proposals of legislative changes. </li>
    </ul>`,
  },
  conditions: {
    title: 'General Terms a Conditions ',
    title2: 'Rights Relating to Personal Data Processing ',
    text1: `Art. I <br/> Opening Provisions`,
    text2: `<ol>
    <li>The subject of these Terms and Conditions of the Slovakiana web portal (hereinafter “Terms and Conditions”) is to define the terms of use, the rights, and the obligations of the operator and the users of the services offered by the Slovakiana web portal.</Li>
     <li>The cultural heritage portal Slovakiana (hereinafter “Web Portal” or “Slovakiana Web Portal”) is a website presenting information about the Slovak cultural heritage to the general public primarily using the website address – <a href='https://www.slovakiana.sk' target='_blank'>www.slovakiana.sk</a>.</li>
     <li>The Slovakiana Web Portal is operated by the government funded National Cultural Centre - Národné osvetové centrum, štátna príspevková organizácia, Nám. SNP č. 12, 812 34 Bratislava 1, Business ID 00164615 (hereinafter “Operator”).</li>
  </ol>`,
    text3: `Art. II <br/>
    Ensuring Access to Cultural Heritage`,
    text4: `<ol>
    <li> The primary goal of the Slovakiana Web Portal is to make cultural objects available in digital form to the general public. For the purposes hereof, the term ‘cultural objects’ means any cultural objects managed by repository institutions of the Slovak Republic (hereinafter “RI”) that are the providers of cultural objects accessible through the Slovakiana Web Portal.</li>
    <li> The cultural objects displayed on the Slovakiana Web Portal may be subject to the copyright of the authors of such objects, RI rights, rights of the Operator, or other third parties.</li>
    <li> The descriptive metadata that is part of the Slovakiana Web Portal has been created by the Slovakiana staff or by other professionals within the RI, and they express the current status of knowledge about the cultural objects. </li>
    <li> Copyright-wise, the content published by the Slovakiana Web Portal may be:
      <ol type = "a">
        <li> <b> Non-copyrighted </b> - freely available, and any further use of the objects by the User is not subject to any limitations except for the moral rights of the author to the work and the rights relating to personality protection. Non-copyrighted works are freely available to all users,</li>
        <li> <b> Copyrighted  </b> - any cultural object protected by copyright. The use of such an object depends on the licence terms attached to the copyrighted cultural object. The availability of a copyrighted cultural object depends on the provisions of the applicable licence agreements. </li>
        <li> <b> Protected by other rights  </b> - any cultural object protected by other rights (such as industrial property rights, the right of personality protection, and other economic rights). Any use depends on the provisions of the applicable licence agreements or other documents enabling further use of the cultural object.</li>
        <li> <b>Outside of copyright protection</b> - objects that do not meet the definition of copyrighted work under Section 3 of Act No. 185/2015 Coll. (Slovak Copyright Act) as amended or are not covered by the industrial property rights. These cultural objects are available to all users, although some information may be redacted or unavailable due to the protection of personal data or other personality rights.</li>
        </ol>
    </li>
    </li>The relevant RI that made the cultural objects available is responsible for the accuracy of the metadata and the applicable licence terms of the cultural objects. `,
    text5: `Art. III <br/>
    Rights and Obligations of Users	`,
    text6: `<ol>
<li> By using the Slovakiana Web Portal, the User represents that they have read the Terms and Conditions of Slovakiana, understood the same, and agreed to abide by the Terms and Conditions. </li>
    <li> The User is entitled to browse the information available on the Slovakiana Web Portal, browse the cultural objects and the RI metadata of the Slovakiana Web Portal, and use the available functionality depending on the current setup of the portal. </li>
    <li> The User shall use the Web Portal content at their own risk and observe the rules reflecting the corresponding degree of legal protection.</li>
    <li> The User acknowledges that the Slovakiana Web Portal may be unavailable due to scheduled maintenance or for a period necessary to fix any potential technical issues.</li>
    <li> The User is entitled to use the services of the Slovakiana Web Portal solely in compliance with the applicable legal regulations valid in the Slovak Republic, these Terms and Conditions, instructions of the Operator, Internet security principles, and good manners.</li>
    <li>The User is not entitled to interfere with the operation and functionality of the Slovakiana Web Portal, alter or otherwise misuse any information, and they are prohibited from trying to obtain unauthorised access to the web portal services.</li>
    <li>Should the User have doubts about the accuracy of any information, they may notify the Operator. The Operator then may inform the object administrator (RI) responsible for the information published about the cultural objects available at Slovakiana and apply for correction of any inaccuracies.</li>
    </ol>
    `,
    text7: `Art. IV <br/>
    Rights of Operator`,
    text8: `<ol>
    <li>The Operator reserves the right to change these Terms and Conditions of Slovakiana at any time at its discretion.</li>
    <li>The Operator reserves the right to change at any time the method of operating the Slovakiana Web Portal and the services that the web portal offers and to interrupt or suspend the operation of the web portal.</li>
    <li>The Operator reserves the right to deny or restrict access to the Slovakiana Web Portal to the User if the User has violated the Terms and Conditions.</li>
    <li>Any and all information submitted to the Operator through the Slovakiana Web Portal shall be considered to be in the ownership of the Operator. The Operator is entitled to freely use the information unless the information is copyrighted or subject to other restrictions following from generally binding legal regulations.</li>
    <li>All copyright and other rights relating to the functionality, services, databases, visual depiction, and content of the services are owned by the Operator or third parties that entered into the appropriate contracts or agreements with the Operator for this purpose.</li>
    </ol>`,
    text9: `Art. V <br/>
    Liability Restrictions`,
    text10: `<ol> <li> The Operator is not liable for any direct or indirect damage that the User may incur in relation to the use of the contents available at the Slovakiana Web Portal.</li>
    <li> The Operator is not liable for any direct, indirect, or consequential damage, including loss of profit, losses or expenses incurred in relation to the Slovakiana Web Portal, the use thereof or the inability to use it, or any breakdown, error, service interruption, system attack, and unauthorised content alteration.</li>
    <li> The Operator shall not be liable for any costs incurred by the User in relation to the display and use of the Slovakiana Web Portal.</li>
    <li> Hyperlinks are provided with cultural objects for reference and to add information.  The Operator is not liable for the content published on third-party websites linked through the hyperlinks. The Operator provides the links in good faith that the content linked abides by the principles of morality and the generally applicable legal regulations.</li>
    <li>The Operator is in no way liable for any damage incurred as a result of any amendments to the Terms and Conditions of the Slovakiana Web Portal, suspension or change in service, loss of data, or the banning of access to registered or non-registered Users by the Operator or by limiting the User access by the Operator. </li>
    </ol>`,
    text11: `Art. VI<br/>
    Data Protection`,
    text12: `<ol><li>When visiting the Slovakiana Web Portal and using the site, the User may provide personal data (e-mail address) to the Operator. It is understood that the User grants their consent by submitting their personal data (e-mail address) through an electronic form located at the Slovakiana Web Portal (subscribing to the newsletter). The consent remains in place until revoked by the User. The User expressly agrees to:
    <ul><li>the processing of personal data subject to the terms and conditions of Regulation (EU) 2016/679 on the protection of natural persons with regard to the processing of personal data, and repealing Directive 95/46/EC (General Data Protection Regulation) (hereinafter “GDPR”), and Act No. 18/2018 Coll. on the protection of personal data and amending certain other acts, as amended,</li>
    <li>the use of personal data in the scope necessary to achieve the objective provided in Art. IX (3) hereof,</li>
    <li>providing certain personal data to persons under contract with the Operator.</li></ul>
    </li>
    <li>By providing their personal data voluntarily, the User represents that the data provided are correct, accurate, and up-to-date. Failing that, the User is liable for any potential damage that the Operator may incur due to incorrect, inaccurate, or outdated information.  The User shall report any change to their personal data to the Operator.</li>
    <li>The Operator processes the personal data of the User for the following purposes:
    <ul><li>o	to send out marketing products (newsletter) of the Operator or persons under a particular contract with the Operator.</li>
    </ul>
    <li>The Operator may process the following personal data or records of personal nature: e-mail address.</li>
    <li>User consent applies to all processing operations involving personal data with identical purposes.</li>
    <li>The User may revoke their consent to the processing of personal data at any time:
    <ul><li>o	by e-mailing Slovakiana at <a href='mailto:slovakiana@nocka.sk'>slovakiana@nocka.sk</a>,</li>
    <li>o	by unsubscribing from the newsletter. </li>
    </ul>
    </li><li>Personal data of the User may be provided to third parties by way of a contact or order form, provided the Operator has entered into an agency contract with the third parties.</li>
    <li>The term ‘entitled person’ refers to any person that comes into contact with the personal data of the User as part of their work relationship or based on the authorisation of the Operator. The User may file a written application with the Operator to receive information about the identity of the entitled person that obtains the personal data on behalf of the Operator.</li>
    <li>Subject to the terms and conditions of the GDPR, the User is entitled to ask the Operator to access the personal data concerning the User and the right to have the personal data corrected or erased and the right to restriction of processing of the data or the right to object to the processing and the right to data portability. Detailed information about the rights the User has in relation to personal data protection is provided in Articles 15 to 23 of the GDPR.</li>
    <li>The Operator has appointed a data protection officer; the User may contact the officer in relation to any queries regarding the processing of their personal data and exercise of the rights under the GDPR. The data protection officer may be contacted by e-mail at: e-mail: <a href='mailto:slovakiana@nocka.sk'>slovakiana@nocka.sk</a> or by mail at: Národné osvetové centrum, Nám. SNP 12, 812 34 Bratislava, envelope marked for the attention of the Data Protection Officer.</li>
    <li>Rights of the User may be restricted only where such restriction follows from a special law or where the exercise of the User’s rights would compromise the protection of the User or prejudice the rights and freedoms of other data subjects.  </li>
    <li>The User may object by way of a written application filed with the Operator to processing their personal data for a purpose other than the purpose specified herein.</li>
    <li>The Operator shall not disclose the personal data of the User or transfer the same to third countries.</li>
    <li>Where the User believes that the processing of personal data that concerns them is at variance with the GDPR, they shall inform the data protection officer or the Operator directly without undue delay. In the event the data protection officer or the Operator fails to take action, the User may file a complaint with the Office for Personal Data Protection of the Slovak Republic: Úrad na ochranu osobných údajov Slovenskej republiky, Hraničná 12, 820 07  Bratislava 27, Tel. <a href='tel:+421 /2/ 3231 3214'>+421 /2/ 3231 3214</a>, E-mail: <a href='mailto:statny.dozor@pdp.gov.sk'>statny.dozor@pdp.gov.sk</a> or with another competent agency in compliance with Art. 77 GDPR.</li>
    <li>During the use of the Slovakiana Web Portal, the User may be required to allow cookies, i.e., text files with small pieces of data that the Internet browser stores in the device the User uses to access the Slovakiana Web Portal. When visiting and using the Slovakiana Web Portal, the User may allow the cookies to be stored in their browser and allow the Operator to use them for advertising or statistical purposes. The consent of the User shall remain in place until the settings of the Internet browser are changed. Once the browser settings are changed or the User bans the use of cookies, the consent is revoked. </li>
    <ul><li><b>Persistent cookies  </b> - are used mainly to remember the personal settings of the Internet browser used by the User. These cookies are stored in the User's Internet browser even after the device, which is used to access the web portal, is switched off. Provided persistent cookies are allowed, the User may benefit from a broader scope of services offered by the Slovakiana Web Portal, such as storing their favourite articles and cultural objects in a “Favourites” folder. These can be accessed only on the device that has used this functionality and only until the User deletes the memory of the device.</li>
    </ul></li>
    </ol>`,
    text13: `Art.VII <br/>
    Closing Provisions`,
    text14: `<ol> <li> Any communication between the Operator and the User shall be in electronic or written form. Any notices of the User and the Operator shall be deemed delivered once the receipt of e-mail or letter delivery is delivered to the other party. </li>
    <li> These General Terms and Conditions become valid and effective on the publication date.</li>
    <li> The Terms and Conditions are executed for an indefinite period. They become binding on the User and the Operator when the User accesses the Slovakiana Web Portal for the first time. The Terms and Conditions are governed by Slovak laws, and any matters not expressly provided herein shall be governed by the relevant legal provisions, in particular by the Civil Code, Criminal Code, Copyright Act, Advertising Act, Trademark Act, and others. </li>
    <li> The Operator is entitled to amend these Terms and Conditions. The Operator shall publish the amended Terms and Conditions at the Slovakiana Web Portal in advance. A new version of the Terms and Conditions comes into effect on the publishing date at the Slovakiana Web Portal.</li>
    <li> This version of the Terms and Conditions is published on June 6, 2023</li>
    </ol>`,

    text21: `Proper processing of your personal data is of great importance to us, and so is the protection of your data. You have the following rights when it comes to the processing of your data:
    <br/>
    <ol type="a">
    <li><b>Information about the processing of your data</b><br/>
    This information includes the identity and the contact data of the operator, its deputy, and the data protection officer, as the case may be, and the processing purpose, categories of personal data concerned, recipient or categories of recipients of the personal data, information regarding the transfer of personal data to third countries, duration of the retention of the personal data, authorised data controllers, list of your rights, the avenue of contacting the Office for Personal Data Protection of the Slovak Republic, source of the processed data, information whether and how automated decision-making and profiling take place.</li>
    <li><b>Right to access data</b><br/>
    You have the right to receive confirmation whether or not your personal data are processed. If your personal data are processed, you have the right to receive information about the purposes of the processing, categories of personal data concerned, recipients or categories of recipients, duration of retention of the personal data, information about your rights, the right to file a complaint with the Office for Personal Data Protection of the Slovak Republic, information about the source of the personal data, information whether automated decision-making and profiling takes place, information and guarantees regarding the transfer of the personal data to a third country or international organisation. You have the right to receive copies of your processed personal data. </li>
    <li><b>Right to obtain correction</b><br/>
    Are your personal data we process outdated or inaccurate? Did your e-mail address change? Please let us know, and we will correct your data.
    <li><b>Right to erasure (right to be forgotten)  </b> In some instances provided by law, we are obliged to erase your personal data upon your request. Each such request is subject to individual assessment as to the performance of the applicable conditions for erasure, for instance, Národné osvetové centrum, Nám. SNP č. 12, 812 34 Bratislava may be obliged or have a legitimate interest to retain the personal data where such legitimate interest outweighs your interests.</li>
    <li><b>Right to restriction of processing</b><br/>
    You may exercise this right if you wish that your personal data be processed solely based on minimum legal grounds or if you want that we block your personal data</li>
    <li><b>Right to data portability</b><br/>
    If you want that we provide your personal data to a different controller or company, we will deliver your data in the respective format to the entity of your choice unless we are prohibited from doing so by legal or other significant obstacles.</li>
    <li><b>Right to object and automated individual decision-making</b><br/>
    Should you find out or believe that your personal data are processed contrary to the principle of protecting your private and personal life or to legal regulations, do not hesitate to contact us and ask for an explanation and demand redress. You may also object directly to automated decision-making, if applicable.</li>
    <li><b>Right to file a motion or complaint  to the Office for Personal Data Protection</b><br/>
    You may file a motion or complaint at any time in the matter of the processing of your personal data to the competent agency, namely the Office for Personal Data Protection of the Slovak Republic with registered seat at Hraničná 12, 820 07 Bratislava 27, Slovak Republic, Business ID: 36 064 220, Tel.:  <a href='tel:+421/2/3231 3220'>+421/2/3231 3220</a>, website <a href='https://dataprotection.gov.sk/uoou/'>https://dataprotection.gov.sk/uoou/</a>.</li>
    </ol>`,
    text22: `You may exercise your rights with the operator, Národné osvetové centrum, Nám. SNP č. 12, 812 34 Bratislava, either by e-mail at <a href='mailto:slovakiana@nocka.sk'>slovakiana@nocka.sk</a> or in writing using the address of the registered seat or the correspondence address of the Operator, Národné osvetové centrum, Nám. SNP č. 12, 812 34 Bratislava.`,
    text23: `Any and all notices and responses to the exercise of your rights are provided free of charge. If, however, your request manifestly lacks any foundation or is unreasonable, in particular, if the request is a repeat request, we are entitled to charge a reasonable fee covering the administrative costs incurred in relation to the provision of the information requested. Any requests for the repeated provision of copies of the processed personal data are subject to a reasonable fee to cover our administrative costs.`,
    text24: `As a rule, we try to respond to requests or provide information on the measures adopted as soon as practicable, however, no later than one month after such request. Where warranted and having regard to the complexity and large number of requests, we are entitled to extend the deadline by two months.  We will inform you accordingly of any such extensions and the reason for the extension.`,
  },
  healthcheck: {
    title: 'Sorry, server is down!',
    message: "Sorry, we can't show you this page, because our servers are down.",
  },
  categoryKO: {
    category1: {
      title: 'Fine art and design',
      pod1: 'Painting',
      pod2: 'Drawing',
      pod3: 'Prints',
      pod4: 'Photography',
      pod5: 'Sculpture',
      pod6: 'Postcard',
      pod7: 'Applied arts and crafts',
    },
    category2: {
      title: 'Text documents',
      pod1: 'Book',
      pod2: 'Periodica',
      pod3: 'Article',
      pod4: 'Old press',
      pod5: 'Archival document',
      pod6: 'Other printed materials',
      pod7: 'Music-related',
      pod8: 'Cartographic document',
    },
    category3: {
      title: 'Historical collection',
      pod1: 'Currency, medals and stamps',
      pod2: 'Equipment, tools, and accessories',
      pod3: 'Furniture and home decoration',
      pod4: 'Weapons, armour, ammunition',
      pod5: 'Musical instruments and accessories',
      pod6: 'Clothing and clothing components',
      pod7: 'Machines',
      pod8: 'Transportation and vehicles',
    },
    category4: {
      title: 'Natural heritage',
      pod1: 'Botany',
      pod2: 'Zoology',
      pod3: 'Geology',
      pod4: 'Palaeontology',
    },
    category5: {
      title: 'Architecture and monuments',
      pod1: 'Architectural monuments',
      pod2: 'Technical monuments',
      pod3: 'Historical monuments',
      pod4: 'Artistic monuments',
      pod5: 'Folk architecture monuments',
      pod6: 'Historical greenery',
      pod7: 'Archaeology ',
      pod8: 'Protected areas and landscapes',
      pod9: 'Architectural designs',
    },
    category6: {
      title: 'Audio-visual content ',
      pod1: 'Movie previews',
      pod2: 'Music previews',
      pod3: 'Instructions',
      pod4: 'Performances',
      pod5: 'Popular-educational videos ',
    },
    category7: {
      title: 'Traditional folk culture',
    },
  },
  categoryVideo: {
    category1: {
      title: '',
      pod1: '',
      pod2: '',
      pod3: '',
      pod4: '',
      pod5: '',
      pod6: '',
    },
    category2: {
      title: '',
      pod1: '',
      pod2: '',
      pod3: '',
    },
    category3: {
      title: '',
    },
  },
  subscribe: {
    validEmail: 'The email must be in the correct format!',
    subcribeSuccess: `You have successfully subscribed to the newsletter`,
    subscribeAlready: `You are already subscribed to the newsletter`,
  },
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  story: {
    category1: `category 1`,
    category2: `category 2`,
    category3: `category 3`,
  },
  cookie: {
    title: 'This website uses cookies',
    text: 'This website uses cookies. We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with Google. By continuing to use this website, you consent to the use of cookies.',
    allow: 'Allow',
    deny: 'Deny',
    necesary: 'Necessary',
    marketing: 'Marketing',
    statistic: 'Statistic',
    options: 'View preferences',
    accept_options: 'Save preferences',
    link: 'More information',
    setting: 'At the moment, cookies are turned',
    on: 'on',
    off: 'off',
  },
  notFoundPage: {
    title: 'Sorry, page not found!',
    desc: 'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
    button: 'Go to Homepage',
  },
  urls: {
    co: '/cultural-objects',
    coId: '/cultural-objects/:id',
    coIdSimilar: '/cultural-objects/:id/:originSimilarName',
    articles: '/articles',
    articlesId: '/articles/:id',
    articleIdName: '/articles/:id/:name',
    home: '/home',
    video: '/videogallery',
    videoId: '/videogallery/:id',
    videoIdSimilar: '/videogallery/:id/:originSimilarName',
    map: '/map',
    mapId: '/map/:id',
    mapIdName: '/map/:id/:name',
    sheets: '/census-forms',
    sheetsId: '/census-forms/:id',
    slovakiana: '/about-slovakiana',
    edu: '/edu-materials',
    eduId: '/edu-materials/:id',
    favorites: '/favorites',
    institutions: '/administrative-institutions',
    institutionsId: '/administrative-institutions/:id',
    oldMap: '/trip-tips',
    oldMapId: '/trip-tips/:id',
    oldMapIdName: '/trip-tips/:id/:name',
    accesibility: '/accessibility-statement',
    conditions: '/general-conditions',
    authorRights: '/author-rights',
    other: '*',
  },
};

export default en;
